import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { PRODUCTS } from "data/data";
import {Transition} from "@headlessui/react";
import TabFilters from "../components/TabFilters";
import SidebarFilters from "./SidebarFilters";
import Nav from "../shared/Nav/Nav";
import NavItem2 from "../components/NavItem2";


import {useLocation, useNavigate, useParams} from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";
import axios from "axios";
import Checkbox from "../shared/Checkbox/Checkbox";
import useCartStore from "../store/cartStore";
import useEshop from "../store/eshopStore";

// @ts-ignore
import debounce from 'lodash/debounce';
import SectionHero2 from "../components/SectionHero/SectionHero2";
import NcImage from "../shared/NcImage/NcImage";
export interface ProductsSearchListProps {
  className?: string;
}

const ProductsSearchList: FC<ProductsSearchListProps> = ({ className = "" }) => {
  const [productsData, setProductsData]=useState([{name:"sdfs"}])
  const [tabActive, setTabActive] = React.useState("Man");
    const { categorySlug } = useParams();
    // const isInitialMount = useRef(true);
  const queryParams = new URLSearchParams(window.location.search);
  const size = queryParams.get('size');
  const motives = queryParams.get('motives');
  const cat = queryParams.get('cat'); // tag id
  // const categoryUrl = queryParams.get('category'); // tag id
  const pcat = queryParams.get('pcat'); //parent category tag id
  const [fixedAttributes,setFixedAttributes]=useState([165,99,100]);//motivy, farba
  const [productCategoryToLoad,setProductCategoryToLoad]=useState('all')
  const [oldCategory,setOldCategory]=useState('')
  const [searchPhrase,setSearchPhrase]=useState('')
  const [catInfo,setCatInfo]=useState(null)
  const [bottomBanner,setBottomBanner]=useState(null)
   const navigate = useNavigate();
  const [productsAreLoading,setProductsAreLoading]=useState(false)
  const [isProduktyPath,setIsProduktyPath]=useState(false)
  const [showEmptyCategoriesInFilters,setShowEmptyCategoriesInFilters]=useState(false)
  const [products,setProducts]=useState([])
  const [fixedCategoryAttributesValues,setFixedCategoryAttributesValues]=useState([])
  const [fixedCategoryAttributes,setFixedCategoryAttributes]=useState([])
  const [categoryAttributesValues,setCategoryAttributesValues]=useState([])
  const [categoryAttributes,setCategoryAttributes]=useState([])
  const [subFilters,setSubFilters]=useState([])
  const [productCategories,setProductCategories]=useState(null)
  const [productMotives,setProductMotives]=useState(null)
  const [loadedProductsMeta,setLoadedProductsMeta]=useState(null)
  // const [filterStates, setFilterStates] = useState<string[][]>([[]]);
  const [filtersAreChanged,setFiltersAreChanged]=useState(false)
  const [catsAreLoading,setCatsAreLoading]=useState(false)
  const [filterSubOptionsAreLoading,setFilterSubOptionsAreLoading]=useState(false)
  let typTimer: NodeJS.Timeout | null=null

    const [allProductsCount,setAllProductsCount]=useState(0)
    const [loadedProductsCount,setLoadedProductsCount]=useState(0)
    const [pageFrom,setPageFrom]=useState(1)
    const [productsLoadCount,setProductsLoadCount]=useState(30)
    const [currentPage,setCurrentPage]=useState(1)

    // Get the value of the 'cat' parameter
    const cart = useCartStore((state) => state.cart);
    const addItem = useCartStore((state) => state.addItem);
    const removeItem = useCartStore((state) => state.removeItem);
    const clearCart = useCartStore((state) => state.clearCart);
    const eshop = useEshop((state) => state.settings);

// new way of handling product search list

    const filterStateRef = useRef({
        filters: {},
        searchPhrase: '',
        category: 'all',
        page: 1,
        productsCount: 40,
        alreadyLoading:false
    });

    const location = useLocation();
    // const isInitialMount = useRef(true);

    useEffect(() => {
        const isProduktyPath = location.pathname.includes('/produkty');
        setIsProduktyPath(isProduktyPath);
    },[location]);

    useEffect(() => {
        // console.log(filterStateRef.current)
        /* if (isInitialMount.current) {
            isInitialMount.current = false;
        } else { */
            const params = new URLSearchParams(location.search);
            const filters = JSON.parse(params.get('filters') || '{}');
            const searchPhraseFromUrl = params.get('search') || '';
            // @ts-ignore
            const page = parseInt(params.get('page'), 10) || 1;
            // @ts-ignore
            const productsCount = parseInt(params.get('productsCount'), 10) || 40;

            const pathSegments = location.pathname.split('/');
            const category = pathSegments[2] || 'all';
                setSearchPhrase(searchPhraseFromUrl)
            // console.log(filterStateRef.current)
            filterStateRef.current = {
                filters:filters,
                searchPhrase:searchPhraseFromUrl,
                category:category,
                page:page,
                productsCount:productsCount,
                alreadyLoading:false
            }
            // console.log(filterStateRef.current)
            setTriggerLoadProduct(1)
        console.log('useff producsearch started')
        console.log(filterStateRef.current)

        // }
        // @ts-ignore
    }, [categorySlug]);
    // }, [location.search, location.pathname, setFilterState]);

// @ts-ignore
    const generateUrlFromState = (filterState) => {
        const params = new URLSearchParams();
        if (Object.keys(filterState.current.filters).length > 0) {
            params.set('filters', JSON.stringify(filterState.current.filters));
        }
        if (filterState.current.searchPhrase) {
            params.set('search', filterState.current.searchPhrase);
        }
        if (filterState.current.page !== 1) {
            params.set('page', filterState.current.page.toString());
        }
        if (filterState.current.productsCount !== 10) {
            params.set('productsCount', filterState.current.productsCount.toString());
        }
        return `/produkty/${filterState.current.category}?${params.toString()}`;
    };
    // const [filterStateRef, setFilterState, triggerLoadProduct] = useFilterState();
    // const location = useLocation();
    // const navigate = useNavigate();
    // useUrlParams(setFilterState);

const [triggerLoadProduct, setTriggerLoadProduct]=useState(0)

    useEffect(() => {
        // @ts-ignore
        // console.log('triggerLoadProduct useeff:', filterStateRef.current)
        if(triggerLoadProduct&&filterStateRef.current.category&&!filterStateRef.current.alreadyLoading) {
            filterStateRef.current.alreadyLoading=true
            console.log('Loading products with state:', filterStateRef.current)
            setTriggerLoadProduct(0)
            loadProducts();
        }
    }, [triggerLoadProduct]); // Use triggerLoadProduct to control effect
// @ts-ignore
    const handleFiltersChange = (newFilters) => {
        // @ts-ignore
        const newState = { ...filterStateRef.current, filters: newFilters };
        // @ts-ignore
         filterStateRef.current.filters=newState.filters
        // @ts-ignore
        //setFilterState(newState);
        const newUrl = generateUrlFromState(filterStateRef);
        navigate(newUrl);
        setTriggerLoadProduct(1)
    };
// @ts-ignore
    const handleSearchPhraseChange = (newSearchPhrase) => {
         // console.log('handleSearchPhraseChange ',newSearchPhrase)
        // @ts-ignore
        // const newState = { ...filterStateRef.current, searchPhrase: newSearchPhrase };
        filterStateRef.current.searchPhrase=newSearchPhrase
        // @ts-ignore
        // setFilterState(newState);
        const newUrl = generateUrlFromState(filterStateRef);
        navigate(newUrl);
        if(filterStateRef.current.category&&!filterStateRef.current.alreadyLoading) {
            filterStateRef.current.alreadyLoading = true
            loadProducts()
        }
        //setTriggerLoadProduct(1)
    };
// @ts-ignore
    const handleCategoryChange = (newCategory) => {
        // @ts-ignore
        // const newState = { ...filterStateRef.current, category: newCategory };
        // @ts-ignore
        // setFilterState(newState);
        filterStateRef.current.searchPhrase=''
        filterStateRef.current.filters={}
        filterStateRef.current.page=1
        // filterStateRef.current.category=newState.category
        filterStateRef.current.category=newCategory
        const newUrl = generateUrlFromState(filterStateRef);
        navigate(newUrl);
        setTriggerLoadProduct(1)
    };
// @ts-ignore
    const handlePageChange = (newPage) => {
        // @ts-ignore
        const newState = { ...filterStateRef.current, page: newPage };
        // @ts-ignore
        // setFilterState(newState);
        filterStateRef.current.page=newPage
        const newUrl = generateUrlFromState(filterStateRef);
        navigate(newUrl);
        setTriggerLoadProduct(1)
    };
// @ts-ignore
    const handleProductsCountChange = (newProductsCount) => {
        // @ts-ignore
        const newState = { ...filterStateRef.current, productsCount: newProductsCount };
        // @ts-ignore
        setFilterState(newState);
        const newUrl = generateUrlFromState(filterStateRef);
        navigate(newUrl);
    };


//******MAIN FUNCTIONS
    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop-150,
                behavior: 'smooth',
            });
        }
    };
  const loadProducts = (searchterm=null,useSearchPhraseFromState=true,cleanProps=false,propPageFrom=1,propCount=30) => {
       console.log('calling loadProducts')
      //if i dont' want to include search term in searching,
          // use(loadProducts(null, false))
      //if from searcg input (use(loadProducts(null, true))
      //if directly (use(loadProducts('term to search', false))
      // addIdToFilterStates("173")
    // console.log( 'filterStateRef.current' );
      // @ts-ignore
    // console.log( filterStateRef.current );
      setProductsAreLoading(true)
    let headers={}
/*
      if(useSearchPhraseFromState){ // @ts-ignore
          searchterm=searchPhrase;
      }
      let props=[[]];
      if(cleanProps) {
          setFilterStates([[]])
          // console.log('setujem filters states na [[]]')
      }

      if (cat && pcat) {
             // console.log('pcat a cat:',pcat, cat)
              if ((!filterStates)||(filterStates.length === 0)||(filterStates[0]&&filterStates[0].length===0)) {

                  // @ts-ignore
                  props[pcat]=[cat];
                  console.log('props ak neni filterstates')
                  console.log(props)
              } else {
                  console.log('props ak je filterstates')
                  console.log(props)
                  // @ts-ignore
                  props= filterStates;
                  // @ts-ignore
                  // props[pcat]=[cat];

                  if (props[pcat]&&!props[pcat].includes(cat)) {
                      let updatedProps = {
                          ...props,
                          // @ts-ignore
                          [pcat]: [...(props[pcat] || []), cat]
                      };
                      props = updatedProps
                  }
                  console.log('props ak je filterstates po update')
                  console.log(props)
              }

          }else{

              // @ts-ignore
              if(!cleanProps)props= filterStates;
  //            console.log('props ak ak nie je pcat')
//              console.log(props)
          }
*/
      setPageFrom(propPageFrom)
      setProductsLoadCount(propCount)
      // console.log( 'filterStates:');
      // console.log(filterStates );
/*
      filters: {},        // e.g., { "99": ["107", "208"], ... }
      searchPhrase: '',
          category: '',
          page: 1,
          productsCount: 10,
      */

      const params = {
      'action': 'get_products',
      // 'pageFrom': propPageFrom,
          // @ts-ignore
      'pageFrom': filterStateRef.current.page,
          // @ts-ignore
      'count': filterStateRef.current.productsCount,
          // @ts-ignore
      'category': filterStateRef.current.category,
      'old_cat': oldCategory,
          // @ts-ignore
      'search': filterStateRef.current.searchPhrase,
      'fixed_attributes': fixedAttributes,
      'show_empty_categories': showEmptyCategoriesInFilters,
        /*'props':(cleanProps&&!cat)
            ? []
            : (cat
                ? (
                    Array.isArray(filterStates)
                        ? (
                            filterStates[0]
                                ? [ [ ...filterStates[0], cat ], ...filterStates.slice(1) ]
                                : [ [ cat ], ...filterStates.slice(1) ]
                        )
                        : [ [ cat ] ]
                )
                : Array.isArray(filterStates) ? filterStates : [])*/
        // 'props':(cleanProps&&!(cat&&pcat))?[]:((cat&&pcat)?[...filterStates, cat]:filterStates),
        // 'props':(cleanProps&&!(cat&&pcat))?[]:filterStates,
          // @ts-ignore
          'props':filterStateRef.current.filters,





    }
    axios.get('https://api.mojavec.com/v1/get/', {
      headers,
      params
    })
        .then((response => {
           // console.log('get_products response: ',response)
          if (response.data.data) {

            const data = response.data.data;
            const meta = response.data.meta;
            if (data.catInfo) {
              setTabActive(data.catInfo.name)
              setCatInfo(data.catInfo)
              setOldCategory(data.catInfo.slug)
            }
            if (data.products) {
                // if(data.active_filters)setFilterStates(data.active_filters)
                if(data.active_filters)filterStateRef.current.filters=data.active_filters
                /* toto je kod,ktory mal riesit vycistenie zaskrtnutych vlastnosti napr. ak som mal zaskrtnute bezove tricko a klikol som na
                hrncek, ostalo zaskrtnute,ze bezove,ale bezovy hrncek nenaslo, co bolomatuce a preto som chcel vycistit stavy od tych zaskrtnutych, ktore vlastnoisti sa nenachadzali pri hrncekoch. Ale to som nemohol vediet, co sa mi vrati z
                 backendu, lenze na backed sa uz poslalo, ze sa ma hladat bezovy hrncek, ktory nenaslo

                 riesenim je pre kazdu kategorie vycistit filterstates
                */
/*
                console.log("filterStates");
                console.log(filterStates);
              // @ts-ignore
                const getValidIds = (referenceObject) => {
                    // @ts-ignore
                    return Object.values(referenceObject).flatMap(subArray => subArray.map(obj => obj.id));
                };

                 console.log(data.fixed_category_attribute_values)
                 console.log(getValidIds(data.fixed_category_attribute_values))

                const validIds = new Set([
                    ...getValidIds(data.fixed_category_attribute_values),
                    ...getValidIds(data.category_attribute_values),
                ]);
// @ts-ignore
                const filterInvalidValues = (filters) => {
                    const filteredFilters = {};
                    for (const categoryId in filters) {
                        // @ts-ignore
                        const validFilterIds = filters[categoryId].filter(id => validIds.has(id));
                        if (validFilterIds.length > 0) {
                            // @ts-ignore
                            filteredFilters[categoryId] = validFilterIds;
                        }
                    }
                    return filteredFilters;
                };

                const cleanedFilters = filterInvalidValues(filterStates);

                console.log('cleanedFilters');
                console.log(cleanedFilters);

    // @ts-ignore
                 setFilterStates(cleanedFilters);


*/


                // console.log('got products: ',data.products)
                const updatedProducts = data.products.map((product: { tags: string | string[]; count: number; color: string; size: string; product_type?: string; is_hashed_path: string;image: string; created_at_hard: string; productImgPath?: string; selectedCut?: string; cutFemale?: boolean; cutMale?: boolean; cutUnisex?: boolean; }) => {
                    let productType = '';
                    if (product.tags.includes("98")) productType = 'tShirt'; //tricko
                    if (product.tags.includes("127")) productType = 'sweatShirt'; //mikina
                    if (product.tags.includes("217")) productType = 'cap'; //siltovka

                    let productImgPath='';
                    if (product.is_hashed_path==="1") {
                        productImgPath = eshop.mainUrl + 'web_files/' + product.image;
                    } else {
                        const imgDate = new Date(product.created_at_hard);
                        let imgYear = imgDate.getFullYear();
                        let imgMonth = String(imgDate.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
                        productImgPath = eshop.mainUrl + 'web_files/' + imgYear + '/' + imgMonth + '/' + product.image;
                    }

                    const getInitialCutValue = (
                        product: { tags: string | string[]; }) => {
                        if (product.tags.includes("153")) return 'damsky_strih';
                        if (product.tags.includes("152")) return 'pansky_strih';
                        if (product.tags.includes("174")) return 'unisex_strih';
                        return ''; // or some default value
                    };

                    product.selectedCut= getInitialCutValue(product)
                    product.productImgPath = productImgPath
                    product.count = 1
                    product.color = ''
                    product.size =(productType==='tShirt'||productType==='sweatShirt')? 'L':''
                    product.cutFemale= (product.tags.includes("153")) ? true : false
                    product.cutMale= (product.tags.includes("152")) ? true : false
                    product.cutUnisex= (product.tags.includes("174")) ? true : false
                    product.product_type = productType
                    // console.log('updproduct:',product)
                    return product;
                });
                // console.log('updatedproducts:',updatedProducts)
                if(filterStateRef.current.page===1)
                { // @ts-ignore
                    setProducts([...updatedProducts]);
                    // console.log("updatedProducts")
                    // console.log(updatedProducts)
                }else { // @ts-ignore
                    setProducts((prevProducts) => [...prevProducts, ...updatedProducts]);
                }
                // setProducts(data.products)
                // scrollToSection('product_grid')
            }
              if (meta) setLoadedProductsMeta(meta)
              //if(filterStateRef.current.page===1) {

                  if (data.categories) setProductCategories(data.categories)
                  if (data.category_attributes) setCategoryAttributes(data.category_attributes)
                  if (data.category_attribute_values) {
                      setCategoryAttributesValues(data.category_attribute_values)
                  }
                  if (data.fixed_category_attributes) setFixedCategoryAttributes(data.fixed_category_attributes)
                  if (data.fixed_category_attribute_values) {
                      setFixedCategoryAttributesValues(data.fixed_category_attribute_values)
                  }
              // }else{

              // }
              // clearUnnecessaryStates(data.fixed_category_attribute_values,data.category_attribute_values)
              // console.log('filterStates')
              // console.log(filterStates)
              // renderSubFilters()
              // renderFixedSubFilters()

          }else
          if (response.data.error) {
            const error = response.data.error;
            // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
          }else{setProducts([])}
            filterStateRef.current.alreadyLoading=false
          setProductsAreLoading(false)
            renderFixedSubFilters()
            renderSubFilters()
        }))
        .catch(error => {
            filterStateRef.current.alreadyLoading=false
          setProductsAreLoading(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })

  }


/*
  useEffect(()=> {
    // console.log('categorySlug:',categorySlug);
      console.log('going to loadproducts categorySlug',categorySlug);
    // if(categorySlug&&!productsAreLoading)loadProducts(null, true)

      if (categorySlug && !productsAreLoading) {
          // loadProducts(null, true,!(cat&&pcat));
          loadProducts(null, true,true);
      }

      /*if (isInitialMount.current) {
          isInitialMount.current = false;
      } else {
          if (categorySlug && !productsAreLoading) {
              loadProducts(null, true,true);
          }} */

     /*     },[categorySlug,cat,pcat])
*/
/*
  useEffect(()=> {
    // console.log('filtersAreChanged:',filtersAreChanged);
    // console.log('filterStates:',filterStates);
    // console.log('filtersAreChanged:',filtersAreChanged);
    if(categorySlug&&!productsAreLoading&&filtersAreChanged) {
        console.log('going to loadproducts from useeffect filtersarechanger');


        loadProducts(null, true)
        setFiltersAreChanged(false)
        renderFixedSubFilters()
        renderSubFilters()
    }
  },[filtersAreChanged])
*/

// @ts-ignore
    const updateFilters = (checked, id, catId, prevFilters) => {
        const updatedCategory = checked
            ? [...(prevFilters[catId] || []), id]
            // @ts-ignore
            : (prevFilters[catId] || []).filter(fid => fid !== id);

        return {
            ...prevFilters,
            [catId]: updatedCategory,
        };
    };

  const handleChangeFilters = (checked: boolean, id: string,catId:string) => {
      // console.log('handleChangeFilters.checked:',checked)
       // console.log('handleChangeFilters.id:',id)
       //console.log('handleChangeFilters.catid:',catId)
      // console.log('filtersAreChanged:',filtersAreChanged);
      /* if(!checked){
          /*eslint no-restricted-globals:0*/
      /*
          const params = new URLSearchParams(location.search);

          // Delete the 'cat' and 'pcat' parameters
          params.delete('cat');
          params.delete('pcat');

          // Construct the new search string
          const newSearch = params.toString();

          // Update the URL without refreshing the page
          navigate({
              pathname: location.pathname,
              search: newSearch ? `?${newSearch}` : ''
          }, { replace: true });

      }
      */
      // setFiltersAreChanged(true)
    /* checked
        ? setFilterStates([...filterStates, id])
        : setFilterStates(filterStates.filter((i) => i !== id));

     */
/*
      setFilterStates((prevFilters) => {
          const updatedCategory = checked
              // @ts-ignore
              ? [...(prevFilters[catId]||[]), id]
              // @ts-ignore
              : (prevFilters[catId]||[]).filter(fid => fid !== id);

          return {
              ...prevFilters,
              [catId]: updatedCategory,
          };
      });
      // handleFiltersChange
 */

      const newFilters = updateFilters(checked, id, catId, filterStateRef.current.filters);
      console.log('newFilters')
      console.log(newFilters)
//          filterStateRef.current.filters=newFilters
      handleFiltersChange(newFilters); // Call with new filter values

      /* setFilterStates((prevFilters) => {
          const newFilters = updateFilters(checked, id, catId, prevFilters);
          console.log('newFilters')
          console.log(newFilters)
//          filterStateRef.current.filters=newFilters
           handleFiltersChange(newFilters); // Call with new filter values
          return newFilters;
      });
*/
  };

  const renderFixedSubFilters = () => {
    type CatAttribute = {
      name: string;
      id: number;
      slug: string;
    };

// console.log('renderujem fixedsubfilters')

      return (


                  fixedCategoryAttributes?.map((item:CatAttribute,index) => (<div key={index}>
                {/* console.log('item:',item) */ }
                      <div className="relative flex flex-col pb-8 space-y-4" >

              <h3 className="font-semibold mb-2.5 mt-10">{item.name}</h3>
                          {/* console.log('filterstates:',filterStates) */}
                        {

                          // @ts-ignore
                          fixedCategoryAttributesValues[parseInt(item.id)].length && fixedCategoryAttributesValues[parseInt(item.id)].map((itemVal:CatAttribute,index) => (
                                <div key={index}>
                                {/* console.log('itemVal:',itemVal) */}
                                {/* console.log('itemCat:',item) */}

                                { // @ts-ignore
                                    /*console.log(filterStates[item.id.toString()]?.includes(itemVal.id.toString())) */}

                                <Checkbox
                                    name={itemVal.name}
                                    label={itemVal.name}
                                    checked={
                                        // @ts-ignore

                                            // @ts-ignore
                                         (filterStateRef.current.filters[item.id.toString()]?.includes(itemVal.id.toString()) === true)

                                }
                                    sizeClassName="w-5 h-5"
                                    labelClassName="text-sm font-normal"
                                    onChange={(checked) => handleChangeFilters(checked, itemVal.id.toString(),item.id.toString())}
                                />
                              </div>

                          ))
                        }
            </div>
        </div>
      ))

            );
      }
  const renderSubFilters = () => {
    type CatAttribute = {
      name: string;
      id: number;
      slug: string;
    };


      return (


                  categoryAttributes?.map((item:CatAttribute,index) => (

                      <div className="relative flex flex-col pb-8 space-y-4" key={index}>

              <h3 className="font-semibold mb-2.5 mt-10">{item.name}</h3>
                          {/* console.log('filterstatesrenderSubFilters:',filterStates) */}
                        {
                          // @ts-ignore
                          categoryAttributesValues[parseInt(item.id)]?.length && categoryAttributesValues[parseInt(item.id)]?.map((itemVal:CatAttribute,index) => (
                                <div key={index} className="">
                                <Checkbox
                                    name={itemVal.name}
                                    label={itemVal.name}
                                    // @ts-ignore
                                    checked={   (filterStateRef.current.filters[item.id.toString()]?.includes(itemVal.id.toString()) === true) }
                                    sizeClassName="w-5 h-5"
                                    labelClassName="text-sm font-normal"
                                    onChange={(checked) => handleChangeFilters(checked, itemVal.id.toString(),item.id.toString())}
                                />
                              </div>

                          ))
                        }
            </div>

      ))

            );
      }

    const handleSearch = (event: { target: { value: string | any[]; }; }, value: any, reason: string) => {
      /*
      console.log("onchange")
        console.log(event)
        console.log(value)
        console.log(reason)
*/
        if (reason === 'selectOption') {
            //let navigate = useNavigate();
            //alert('vybrali ste '+value.label+' a idem smerovat do '+value.id);
//            navigate('/'+value.id);
            {
                //if (typeof window !== 'undefined') {
//                    window.location.href = '/gallery/'+value.id;
//                }
            }

        }else{
            //console.log(encodeURIComponent(event.target.value))
            //apiUrl = 'http://localhost:8003/data-api.php?tkn=hj7jyECYp2Vv4JEYBDxBCZhQt2P8zw&action=get_galleries&count='+event.target.value;

            // @ts-ignore
            setSearchPhrase(event.target.value)
            if((event.target.value&&event.target.value.length>2)||(event.target.value.length===0)) {

                //if((!typTimer)&&(!productsAreLoading)) {
                if(!productsAreLoading) {
                    // @ts-ignore
                    clearTimeout(typTimer)
                    typTimer = setTimeout(() => {
                         console.log("going to read " + event.target.value);
//                         console.log('going to loadproducts from event');
                        // @ts-ignore
                        // loadProducts(event.target.value,false)
                        // setFiltersAreChanged(false)



                        handleSearchPhraseChange(event.target.value)

                    }, 500)
                }

            }
        };
    };
    const handleAddItem = (item: any) => {
        // Logic to add item to the cart
        console.log(`Adding item to cart: ${item.name}`);
        addItem(item)
        // Add your cart logic here, e.g., updating state or calling a service
    };

    // @ts-ignore
    return (
        <div className={`nc-ProductsSearchList  ${className}`} data-nc-id="ProductsSearchList">
            <Helmet>
                <title>MojaVec produkty || MojaVec </title>
            </Helmet>

            <div className="container">
                <header className="max-w-2xl mx-auto mt-15 flex flex-col lg:mt-7 mb-10">

                    <form
                        className="relative w-full"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <label
                            htmlFor="search-input"
                            className="text-neutral-500 dark:text-neutral-300"
                        >
                            <span className="sr-only">Search all icons</span>
                            <Input
                                className="shadow-lg border-0 dark:border"
                                id="search-input"
                                type="search"
                                value={searchPhrase}
                                placeholder="Napíšte čo hľadáte..."
                                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                                rounded="rounded-full"
                                // @ts-ignore
                                onChange={handleSearch}
                            />
                            <ButtonCircle
                                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                                size=" w-11 h-11"
                                type="submit"
                            >
                                <i className="las la-arrow-right text-xl"></i>
                            </ButtonCircle>
                            <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </svg>
              </span>
                        </label>
                    </form>
                </header>
            </div>

            {!isProduktyPath && <SectionHero2/>}



            <div className="container py-4 lg:pb-10 space-y-16 lg:space-y-8" >

                <main className="bg-white p-5">
                    {/* FILTER */}
                    {/* <HeaderFilterSearchPage/> */}
                    <Transition
                        show={true}
                        enter="transition-opacity duration-150"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >

                        <Nav
                            className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
                            containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
                        >

                            {
                                // @ts-ignore
                                productCategories && productCategories?.map((item, index) => (
                                    <NavItem2
                                        key={index}
                                        isActive={tabActive === item.name}
                                        onClick={() => {
                                            setTabActive(item.name);
                                            // navigate('/produkty/' + item.slug)
                                            handleCategoryChange(item.slug)
                                        }}
                                    >
                                        <div className="flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm ">
                                            {/*
                      <span
                    className="inline-block"
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                ></span> */}
                                            <span>{item.name}</span>
                                        </div>
                                    </NavItem2>
                                ))}
                        </Nav>
                        {/* <TabFilters onProductsData={handleProductsData}/>  */}
                    </Transition>
                    {/* LOOP ITEMS */}

                    <div>
                        <h1 className="text-2xl 2xl:text-3xl font-semibold text-center">
                            {
                                // @ts-ignore
                                catInfo?.name
                            }
                        </h1>
                        <div className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base text-center">

                            <div dangerouslySetInnerHTML={

                                {
                                    // @ts-ignore
                                    __html: catInfo?.text
                                }}/>


                        </div>

                        <div className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base text-center">

                            {// @ts-ignore
                                loadedProductsMeta?.search_phrase &&
                                <>
                                    vyhľadávanie v názve produktu: "<strong>{// @ts-ignore
                                    loadedProductsMeta?.search_phrase}</strong>"
                                    <br/>
                                    <br/>
                                </>}

                            zobrazujem {
                            // @ts-ignore
                            // loadedProductsMeta?.sent_prod_count
                            products.length
                        } z&nbsp;{
                            // @ts-ignore
                            loadedProductsMeta?.whole_prod_count
                        }
                            <br/>

                            {
                                // @ts-ignore
                                loadedProductsMeta?.whole_prod_count === 0 &&
                                <><br/>
                                    <strong style={{color: "red"}}>Je nám ľúto, nenašli sme žiadne produkty vyhovujúce vášmu vyhľadávaniu.</strong>
                                    {   // @ts-ignore
                                        (filterStateRef.current.filters.length > 0) && <>
                                            <br/>
                                            <br/>
                                            <strong style={{color: "orange"}}>Skúste vypnúť niektoré filtre</strong>
                                        </>
                                    }

                                    {
                                        // @ts-ignore
                                        loadedProductsMeta?.search_phrase && <>
                                            <br/>
                                            <br/>
                                            <strong style={{color: "orange"}}>Skúste odstrániť hľadaný výraz z vyhľadávacieho okna</strong>

                                        </>
                                    }

                                </>
                            }
                            <br/>


                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row">


                        {/* LEFT COLUMN */}
                        <div className="lg:w-1/4 xl:w-1/4 pr-4">
                            <div className="divide-y divide-slate-200 dark:divide-slate-700">
                                {!!fixedCategoryAttributes.length && renderFixedSubFilters()}
                                {!!categoryAttributes.length && renderSubFilters()}
                            </div>
                        </div>

                        {/* PRODUCT LIST */}

                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10" id="product_grid">
                            {
                                (products?.map((item, index) => (
// @ts-ignore
                                    <ProductCard data={item} key={item.id} addItem={handleAddItem} prodType={item.product_type}/>
                                )))
                            }
                        </div>

                    </div>

                    {(products.length > 29) &&
                        <div className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base text-center">
                            zobrazujem {
                            // @ts-ignore
                            // loadedProductsMeta?.sent_prod_count
                            products.length
                        } z&nbsp;{
                            // @ts-ignore
                            loadedProductsMeta?.whole_prod_count
                        }
                            <br/>
                        </div>}

                    <div className="flex flex-col mt-4 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center">
                        {// @ts-ignore
                            (products.length < loadedProductsMeta?.whole_prod_count) &&
                            <ButtonPrimary
                                // @ts-ignore
                                onClick={
                                    // @ts-ignore
                                    () => {
                                        // @ts-ignore
                                        // loadProducts(null, true, false, pageFrom + 1, productsLoadCount);
                                        handlePageChange(filterStateRef.current.page + 1)
                                    }
                                }>Načítaj ďalšie...</ButtonPrimary>
                        }
                    </div>
                    {/*
              <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center">
                  <Pagination/>
              </div>
*/}

                </main>

                {eshop.bottomHomeBanner&&
                <NcImage
                    containerClassName="flex h-full w-full flex-shrink-0 rounded-3xl overflow-hidden"
                    src={eshop.bottomHomeBanner?.img}
                    onClick={() => navigate(eshop.bottomHomeBanner?.link)}
                    style={{cursor: "pointer", marginTop: "60px"}}
                />
                }

            </div>


        </div>


    );
};

export default ProductsSearchList;
