import React, {FC, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Product, PRODUCTS } from "data/data";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import axios from "axios";

export interface ProductCardProps {
  className?: string;
  prodType?:string;
  data?: Product;
  addItem?: (item: Product) => void;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  addItem,
  className = "",
  data = PRODUCTS[0],
  prodType,

  isLiked,
}) => {

  const {
    id,
    name,
    slug,
    price,
    description,
    // product_type:string,
    variants,
    variantType,
    status,
    image,
    product_code,
    tags
  } = data;
  const [variantActive, setVariantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [pidOfQuickView, setPidOfQuickView] = React.useState(0);
  const [allSizes,setAllSizes]=useState(null)

  useEffect(()=> {
    if (tags?.includes("98")) {

      // @ts-ignore
      setAllSizes(["XS", "S", "M", "L", "XL"])
    }
    if (tags?.includes("127")) {

      // @ts-ignore
      setAllSizes(["XS", "S", "M", "L", "XL"])

    }
    // sizes=["XS", "S", "M", "L", "XL"]

  },[])

  // const notifyAddTocart = ({ size }: { size?: string }) => {
  const notifyAddTocart = (item: any) => {
  // console.log(item);

    if (addItem) {
      addItem(data);
    }


    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Produkt sme pridali do košíka!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          <ButtonPrimary
              href="/checkout"
              // @ts-ignore
              onClick={()=>toast.dismiss(t.id)}
              className="flex-1"
          >
            Prejsť do košíka...
          </ButtonPrimary>
          <ButtonPrimary

              // @ts-ignore
              onClick={()=>toast.dismiss(t.id)}
              className="flex-1"
          >
            Pokračovať v nákupe
          </ButtonPrimary>
          {/* renderProductCartOnNotify() */}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {
    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={"https://mojavec.com/web_files/t_"+image}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p>
              </div>
              <Prices price={price} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">Množstvo 1</p>

            <div className="flex">
              {/*
              <Link

                to={"/cart"}
                className="font-medium text-primary-6000 dark:text-primary-500 "
              >
                Zobraz košík
              </Link>
              */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-red-500";
    }
    if (Bgclass.includes("violet")) {
      return "border-violet-500";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("sky")) {
      return "border-sky-500";
    }
    if (Bgclass.includes("yellow")) {
      return "border-yellow-500";
    }
    return "border-transparent";
  };

  const renderVariants = () => {
    if (!variants || !variants.length || !variantType) {
      return null;
    }

    if (variantType === "color") {
      return (
        <div className="flex space-x-1">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative w-6 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${
                variantActive === index
                  ? getBorderClass(variant.color)
                  : "border-transparent"
              }`}
              title={variant.name}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${variant.color}`}
              ></div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="flex ">
        {variants.map((variant, index) => (
          <div
            key={index}
            onClick={() => setVariantActive(index)}
            className={`relative w-11 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${
              variantActive === index
                ? "border-black dark:border-slate-300"
                : "border-transparent"
            }`}
            title={variant.name}
          >
            <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
              <img
                src={variant.thumbnail}
                alt="variant"
                className="absolute w-full h-full object-cover"

              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderGroupButtons = () => {

    return (
      <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
        <ButtonPrimary
          className="shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          // onClick={() => notifyAddTocart({ size: "XL" })}
            // @ts-ignore
          onClick={() => notifyAddTocart(data)}
        >
          <BagIcon className="w-3.5 h-3.5 mb-0.5" />
          <span className="ml-1">Pridať do košíka</span>
        </ButtonPrimary>
        {/*
        <ButtonSecondary
          className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => {setShowModalQuickView(true);setPidOfQuickView(id)}}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">Náhľad</span>
        </ButtonSecondary>
        */}
      </div>
    );
  };

  const renderSizeList = () => {
    // @ts-ignore
    if (!allSizes || !allSizes?.length) {
      return null;
    }





    return (
      <div className="absolute bottom-0 inset-x-1 space-x-1.5 flex justify-center opacity-0 invisible group-hover:bottom-4 group-hover:opacity-100 group-hover:visible transition-all">
        { // @ts-ignore
          allSizes?.map((size, index) => {
          return (
            <div
              key={index}
              className="nc-shadow-lg w-10 h-10 rounded-xl bg-white hover:bg-slate-900 hover:text-white transition-colors cursor-pointer flex items-center justify-center uppercase font-semibold tracking-tight text-sm text-slate-900"
              onClick={() => notifyAddTocart({ data, size })}
            >
              {size}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {
        // @ts-ignore
        // console.log('product type:',prodType)
      }
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
        data-nc-id="ProductCard"
      >
        <Link to={"/produkt/"+slug} className="absolute inset-0"></Link>

        <div className="relative flex-shrink-0  overflow-hidden z-1 group">
          <Link to={"/produkt/"+slug} className="block">
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={"https://mojavec.com/web_files/t_"+image}
              className="object-cover w-full h-full "
              style={{
                border: "1px solid #eaeaea",  // Adds a 2px solid gray border

                borderRadius: "20px"        // Optional: Adds slightly rounded corners
              }}
            />
          </Link>

          <ProductStatus status={status} />

          {/*<LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" />*/}

          {/* allSizes ? renderSizeList() : renderGroupButtons() */}
          {!(prodType === 'cap' || prodType === 'tShirt' || prodType === 'sweatShirt') && renderGroupButtons()}

          {/* product_type */}
        </div>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
          {/*renderVariants()*/}

          <div>
            <h2
              className={`nc-ProductCard__title text-base font-semibold transition-colors`}
            >
              {name}
            </h2>
            <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
              {product_code}
            </p>
          </div>

          <div className="flex justify-between items-end ">
            <Prices price={price} />
            {/* <div className="flex items-center mb-0.5">
              <StarIcon className="w-5 h-5 pb-[1px] text-amber-400" />
              <span className="text-sm ml-1 text-slate-500 dark:text-slate-400">
                {(Math.random() * 1 + 4).toFixed(1)} (
                {Math.floor(Math.random() * 70 + 20)} recenzií)
              </span>
            </div> */}

          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        pidOfQuickView={pidOfQuickView}
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;
