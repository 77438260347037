import React, {FC, useEffect, useRef, useState} from "react";
import imageRightPng from "images/hero-right.png";
import imageRightPng2 from "images/hero-right-2.png";
import imageRightPng3 from "images/hero-right-3.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import useEshop from "../../store/eshopStore";

interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
  fullSize: boolean;
}
export interface SectionHero2Props {
  className?: string;
}

const DATA: Hero2DataType[] = [
  /* {
   image: "https://www.mojavec.com/web_files/bqlZWzpgEBXaybD9eR540m136evKdY.jpg",
   heading: "Výnimočné tričká pre Vaše deti",
   subHeading: "najnovšia ponuka detských tričiek s potlačou 🔥",
   btnText: "Ukáž mi",
   btnLink: "/produkty/tricka?filters=%7B%22165%22%3A%5B%22173%22%5D%7D&productsCount=40",
   fullSize:true
 },
 {
   image: "https://www.mojavec.com/web_files/G7WzEPbZ3vKpREQdxRDwV14XqNmgnr.jpg",
   heading: "Výnimočné tričká pre Vaše deti",
   subHeading: "najnovšia ponuka detských tričiek s potlačou 🔥",
   btnText: "Ukáž mi",
   btnLink: "/produkty/tricka?filters=%7B%22100%22%3A%5B%22128%22%5D%2C%22165%22%3A%5B%22152%22%5D%7D&productsCount=40",
   fullSize:true
 },
 {
   image: "https://www.mojavec.com/web_files/VkdlGxMZrEW9LD7kqy781PebQB63vg.jpg",
   heading: "Výnimočné tričká pre Vaše deti",
   subHeading: "najnovšia ponuka detských tričiek s potlačou 🔥",
   btnText: "Ukáž mi",
   btnLink: "/produkty/hrnceky",
   fullSize:true
 },
  {
   image: "https://www.mojavec.com/web_files/2QLQB8YRbZ.png",
   heading: "Výnimočné tričká pre Vaše deti",
   subHeading: "najnovšia ponuka detských tričiek s potlačou 🔥",
   btnText: "Ukáž mi",
   btnLink: "/produkty/tricka?filters=%7B\"100\"%3A%5B\"192\"%5D%7D&productsCount=40",
   fullSize:false
 },    {
   image: "https://www.mojavec.com/web_files/Z3oden2bNAP5yVVbGyGMqKzX7rBQxj.png",
   heading: "Šiltovky s potlačou",
   subHeading: "Rôzne farby a veľkosti šiltoviek s potlačou 🔥",
   btnText: "Ukáž mi",
   btnLink: "produkty/siltovka/",
   fullSize:false
 },{
   image: "https://www.mojavec.com/web_files/jbxRqxgLpo.png",
   heading: "Exkluzívne tričká pre každého",
   subHeading: "Produkty, z ktorých vám vypadnú oči 🔥",
   btnText: "Ukáž mi",
   btnLink: "produkty/tricka/",
   fullSize:false
 } */
];
let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const eshop = useEshop();
  const navigate = useNavigate();
  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );
  //




  const handleBannerClick = (clink: string) => {
    navigate(clink);
  };
  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= eshop.settings.banners?.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= eshop.settings.banners?.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return eshop.settings.banners?.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // =================

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = eshop.settings.banners[index];
    // console.log('index',index)
    // console.log('item',item)
    if (!isActive) {
      return null;
    }
    // @ts-ignore

    return (
      <div
        className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
        key={index}
      >
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
          {eshop.settings.banners?.map((_: any, index: string | number | ((prevState: number) => number) | null | undefined) => {
            const isActive = indexActive === index;

            return (
              <div
                  // @ts-ignore
                key={index}
                onClick={() => {
                  // @ts-ignore
                  setIndexActive(index);
                  handleAfterClick();
                }}
                className={`relative px-1 py-1.5 cursor-pointer`}
              >
                <div
                  className={`relative w-20 h-1 shadow-sm rounded-md bg-white`}
                >
                  {isActive && (
                    <div
                      className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${
                        isActive ? " " : " "
                      }`}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <Prev
          className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickPrev={handleClickPrev}
        />
        <Next
          className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickNext={handleClickNext}
        />

        {/* BG */}
        <div className="absolute inset-0">
          {/* <div className="absolute inset-0 bg-[#F7F0EA]"> */}
          <img
            className="absolute w-full h-full object-contain"
            src={backgroundLineSvg}
            alt="hero"
          />
        </div>


        {/* <div className="relative container pb-0 pt-14 sm:pt-20 lg:py-44"> */}
            <div className={`relative container pb-0  ${
                item.fullSize ? "" : "lg:py-44"
            }`}>


              {!item.fullSize &&
              <div
                  className={`relative z-[1] w-full max-w-3xl space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}
          >
            <div className="space-y-5 sm:space-y-6">
              <span className="nc-SectionHero2Item__subheading block text-base md:text-xl text-slate-700 font-medium">
                {item.subHeading}
              </span>
              <h2 className="nc-SectionHero2Item__heading font-semibold text-3xl sm:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl !leading-[114%] text-slate-900">
                {item.heading}
              </h2>
            </div>

            <ButtonPrimary
              className="nc-SectionHero2Item__button dark:bg-slate-900"
              sizeClass="py-3 px-6 sm:py-5 sm:px-9"
              href={item.btnLink as any}
            >
              <span>{item.btnText}</span>
              <span>
                <svg className="w-5 h-5 ml-2.5" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </ButtonPrimary>
          </div>}
              {!item.fullSize ?
                  <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
                    <img
                        className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
                        src={item.image}
                        alt={item.heading}

                    />
                  </div> :
                  <div style={{
                    width: "100%",
                    maxWidth: "1280px",
                    height: "auto",
                    aspectRatio: "1280 / 573",
                    maxHeight: "573px",
                  }}>
                  <img
                      className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"

                      src={item.image}
                      alt={item.heading}
                      // @ts-ignore
                      onClick={()=>handleBannerClick(item?.btnLink?item.btnLink:'')}

                      style={{ cursor: 'pointer' }}
                  />
                  </div>

              }
                </div>
                </div>
                );
              };


  return <>


    {eshop.settings.banners?.map((_: any, index: number) => renderItem(index))}


  </>;
};

export default SectionHero2;
