import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button

        style={{ backgroundColor: "#2f7875 !important" }}
      className={`ttnc-ButtonPrimary disabled:bg-opacity-90 mojavec-bg-color dark:bg-slate-100 hover:mojavec-bg-color_hover text-slate-50 dark:text-slate-800 shadow-xl ${className}`}
      {...args}

    />
  );
};

// @ts-ignore
export default ButtonPrimary;
